import React from "react";
import { useGetStatistika } from "../../hooks/query";
import { Loader } from "@mantine/core";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import im1 from "../../assets/images/im1.png";
import im2 from "../../assets/images/im2.png";
import im3 from "../../assets/images/im3.png";
import im4 from "../../assets/images/im4.png";
import im5 from "../../assets/images/im5.png";
import { useTranslation } from "react-i18next";

const Statistika = () => {
  const { t } = useTranslation();
  const useGetStatistikaList = useGetStatistika();
  console.log(useGetStatistikaList);
  if (useGetStatistikaList.isLoading) {
    return (
      <div className="flex justify-center h-[370px] items-center">
        <Loader color="#FF7D00" size="xl" variant="oval" />
      </div>
    );
  }
  return (
    <div className="h-[400px] relative overflow-hidden mb-[50px] bg-[#252B48] mt-8">
      <h3 className="text-center pt-[40px] pb-[50px] font-semibold text-4xl text-white relative z-10">
        {t("Murojaatlar statistikasi")}
      </h3>
      <img
        src={image3}
        alt=""
        width={316}
        height={250}
        className="absolute top-0"
      />
      <img
        src={image2}
        alt=""
        width={277}
        height={275}
        className="absolute right-0"
      />
      <div className="relative z-20 bottom-[100px]">
        <img
          src={im5}
          width={80}
          height={80}
          alt=""
          className="absolute left-[454px] top-[77px] "
        />
        <img
          src={im4}
          width={80}
          height={80}
          alt=""
          className="absolute left-[682px] top-[77px]"
        />
        <img
          src={im3}
          width={80}
          height={80}
          alt=""
          className="absolute left-[910px] top-[77px]"
        />
        <img
          src={im1}
          width={80}
          height={80}
          alt=""
          className="absolute left-[560px] top-[192px]"
        />
        <img
          src={im2}
          width={80}
          height={80}
          alt=""
          className="absolute left-[805px] top-[192px]"
        />
      </div>
      {useGetStatistikaList.data?.map((item) => (
        <div
          key={item.id}
          className="flex flex-col items-center gap-8 relative z-10"
        >
          <div className="flex gap-10">
            <div className="bg-white w-[200px] h-[80px] relative">
              <p className="flex text-[29px] font-bold ml-[20px]">
                {item.appeals}
              </p>
              <h4 className="border-l-[#F9995D] border-l-[3px] ml-[30px] pl-1 text-[16px] text-[#667299]">
                {t("Umumiy murojaatlar")}
              </h4>
            </div>
            <div className="bg-white w-[200px] h-[80px]">
              <p className="flex text-[29px] font-bold ml-[20px]">
                {item.looking_appeals}
              </p>
              <h4 className="border-l-[#F9995D] border-l-[3px] ml-[30px] pl-1 text-[16px]  text-[#667299]">
                {t("Jarayonda")}
              </h4>
            </div>
            <div className="bg-white w-[200px] h-[80px]">
              <p className="flex text-[29px] font-bold ml-[20px]">
                {item.expertiza}
              </p>
              <h4 className="border-l-[#F9995D] border-l-[3px] ml-[30px] pl-1 text-[16px] text-[#667299]">
                {t("Qanoatlantirildi")}
              </h4>
            </div>
          </div>
          <div className="flex gap-10">
            <div className="bg-white w-[200px] h-[80px]">
              <p className="flex text-[29px] font-bold ml-[20px]">
                {item.understand}
              </p>
              <h4 className="border-l-[#F9995D] border-l-[3px] ml-[30px] pl-1 text-[16px] text-[#667299]">
                {t("Tushuntirildi")}
              </h4>
            </div>
            <div className="bg-white w-[200px] h-[80px]">
              <p className="flex text-[29px] font-bold ml-[20px]">
                {item.canceled}
              </p>
              <h4 className="border-l-[#F9995D] border-l-[3px] ml-[30px] pl-1 text-[16px] text-[#667299]">
                {t("Rad etildi")}
              </h4>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Statistika;
