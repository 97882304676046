import { useTranslation } from "react-i18next";
import chinoz from "../../assets/images/chinoz.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center px-[100px] gap-8 h-[330px]">
      <div className="w-[600px]">
        <img src={chinoz} alt="" />
      </div>
      <div className="w-[865px] h-[170px]">
        <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
          {t("Chinoz tumani haqida")}
        </h4>
        <p>
          <strong className="text-[20px]">Chinoz tumani</strong>- Toshkent
          viloyatidagi tuman. 1935-yil 9-fevralda tashkil etilgan. 1962-yil
          24-dekabrda Yangiyoʻl tumaniga qoʻshilgan. 1973-yil 12-aprelda qayta
          tuzilgan. Shimoliy va shim.sharqdan Yangiyoʻl, sharq va jan.sharqdan
          Quyi Chirchiq tumanlari, jan. va gʻarbdan Sirdaryo, shim.gʻarbdan
          Qozogʻiston Respublikasi bilan chegaradosh. Maydoni 0,34 ming km².
          Aholisi 116 ming kishi (2004). Tumanda 1 shahar (Chinoz), 2 shaharcha
          (Olmazor, Yangi Chinoz), 8 qishloq fuqarolari yigʻini (Islohot,
          Ittifoq, Koʻtarma, Turkiston, Chinoz, Eski Toshkent, Yallama,
          Uzbekistan) bor. Markazi — Chinoz shahri.
        </p>
      </div>
    </div>
  );
};

export default RegionInfo;
