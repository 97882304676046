import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/kart.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
// import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#252B48] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Chinoz tumani hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.chinoz.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            {/* <a
              className="hover:text-[#963f9d] duration-300"
              href="https://www.instagram.com/axborot_xizmati_77?igshid=yrbeg6ojuocd"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={26} />
            </a> */}
            {/* <a
              className="hover:text-[#CD201F] duration-300"
              href="https://youtube.com/@ohangaronshaharhokimligi?si=BC3nBaXfsvy1Iyoo"
              target="_blank"
              rel="noreferrer"
            >
              <FiYoutube size={29} />
            </a> */}
            <a
              className="hover:text-[#0088cc] duration-300"
              href="https://t.me/chinozpressa"     
              target="_blank"
              rel="noreferrer"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#3b5998] duration-300"
              href="https://m.facebook.com/login/?next=https%3A%2F%2Fm.facebook.com%2Fstory.php%3Fstory_fbid%3D827639882423021%26id%3D100064004893510%26mibextid%3DZbWKwL&refsrc=deprecated&_rdr"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Hamza M.F.Y. Sh.Rashidov ko'chasi, 12-uy.")}</p>
          <a
            href="https://www.google.com/maps/place/%D0%A7%D0%B8%D0%BD%D0%BE%D0%B7+%D0%A2%D1%83%D0%BC%D0%B0%D0%BD+%D1%85%D0%BE%D0%BA%D0%B8%D0%BC%D0%B8%D1%8F%D1%82%D0%B8/@40.9402025,68.7576747,15.91z/data=!4m10!1m2!2m1!1schinoz+tumani+hokimligi!3m6!1s0x38addb9bccf6a7db:0x1365e8285795efe6!8m2!3d40.941012!4d68.7609462!15sChdjaGlub3ogdHVtYW5pIGhva2ltbGlnaZIBEWdvdmVybm1lbnRfb2ZmaWNl4AEA!16s%2Fg%2F11nmckwtnt?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 593 12 00")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
